<script>
	import Image from '$lib/Components/Layout/Image/Image.svelte';
	import Section from '$lib/Components/Layout/Section/Section.svelte';
	import PostalCode from '$lib/PageSections/Forms/PostalCode/PostalCode.svelte';

	import image from './images/above_sink_installation.jpg?w=500';

	export let data = null;
	export let form = null;
	export let title = 'Get a Free Estimate';
	export let backgroundColor = 'blue';
	export let id = '';
</script>

<Section {backgroundColor} {title} {id}>
	<slot name="image">
		<Image
			src={image}
			loading="lazy"
			class="no-bleed mb-4 max-w-xl rounded-lg shadow-lg"
		/>
	</slot>
	<PostalCode
		{data}
		{form}
		buttonText="CREATE COMFORT NOW"
		buttonTextLead="CONTACT ME ABOUT AN ESTIMATE"
	/>
	<p>
		We value safety and privacy. We will never share, sell, or rent your data to
		third parties not directly related to your purchase or consideration of our
		products. <a href="/privacy-policy">Read our full policy here.</a>
	</p>
</Section>
